import React from 'react';
import { Card, Container } from 'react-bootstrap';
import './Welcome.css';
import Layout from "../../layout/default";


const Welcome = () => {
    return (
        <Layout title="Welcome" content="container">

            <Container className="welcome-container">
                <Card className="text-center welcome-card">
                    <Card.Body>
                        <div className="logo-container">
                            <img
                                src="/images/asmlogo.png"
                                alt="ASM DAIRY Logo"
                                className="welcome-logo"
                            />
                        </div>
                        <h2 className="welcome-title">
                            Welcome to the <span className="company-name">ASM DAIRY Pvt Ltd</span> !
                        </h2>
                        <p className="welcome-subtitle">Simply milk nothing else</p>
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    );
};

export default Welcome; 