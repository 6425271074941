import React, { useEffect, useState } from "react";
import classNames from "classnames";

// import getParents from '../../../utilities/getParents';
import slideUp from "../../../utilities/slideUp";
import slideDown from "../../../utilities/slideDown";
import getParents from "../../../utilities/getParents";

import { NavLink, Link } from "react-router-dom";
import { ProfileDetails } from "../../../pages/masters/store/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";



// <MenuItem sub>
// <MenuItemLink
//   icon="grid-alt"
//   text="Procurement"
//   onClick={menuToggle}
//   sub
// />
// <MenuSub>
//   <MenuItem>
//     <MenuItemLink text="VLCC procurement" to="/procurement" />
//   </MenuItem>
//   <MenuItem>
//   <MenuItemLink text="VLCC Reception entry" to="/vlcc-entry" />
// </MenuItem>
//   <MenuItem>
//     <MenuItemLink text="Bulk Vendor Procurement" to="/bulk-vendor" />
//   </MenuItem>
//   <MenuItem>
//     <MenuItemLink text="Direct Vendor Procurement" to="/direct-vendor" />
//   </MenuItem>

// </MenuSub>
// </MenuItem>

function MenuHeading({ className, text, ...props }) {
  const compClass = classNames({
    "nk-menu-heading": true,
    [className]: className,
  });
  return (
    <li className={compClass}>
      <h6 className="overline-title">{text || props.children}</h6>
    </li>
  );
}

function MenuItemTemplate({ text, icon }) {
  return (
    <>
      {/*         {icon && (
          <span className="nk-menu-icon">
            <FontAwesomeIcon icon={icon} />
          </span>
        )} */}
      {icon && (
        <span className="nk-menu-icon">
          <em className={`icon ni ni-${icon}`}></em>
        </span>
      )}
      {text && <span className="nk-menu-text">{text}</span>}
    </>
  );
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
  return (
    <>
      {!blank && !sub && (
        <NavLink className="nk-menu-link" to={to}>
          <MenuItemTemplate icon={icon} text={text} />
        </NavLink>
      )}
      {blank && (
        <Link className="nk-menu-link" to={to} target="_blank">
          <MenuItemTemplate icon={icon} text={text} />
        </Link>
      )}
      {sub && (
        <a
          className="nk-menu-link nk-menu-toggle"
          onClick={onClick}
          href="#expand"
        >
          <MenuItemTemplate icon={icon} text={text} />
        </a>
      )}
    </>
  );
}
function MenuItem({ sub, className, ...props }) {
  const compClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function MenuSub({ mega, className, ...props }) {
  const compClass = classNames({
    "nk-menu-sub": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function MenuList({ className, ...props }) {
  const compClass = classNames({
    "nk-menu": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function Menu() {
  // variables for Sidebar
  let menu = {
    classes: {
      main: "nk-menu",
      item: "nk-menu-item",
      link: "nk-menu-link",
      toggle: "nk-menu-toggle",
      sub: "nk-menu-sub",
      subparent: "has-sub",
      active: "active",
      current: "current-page",
    },
  };

  let currentLink = function (selector) {
    let elm = document.querySelectorAll(selector);
    elm.forEach(function (item) {
      var activeRouterLink = item.classList.contains("active");
      if (activeRouterLink) {
        let parents = getParents(
          item,
          `.${menu.classes.main}`,
          menu.classes.item
        );
        parents.forEach((parentElemets) => {
          parentElemets.classList.add(
            menu.classes.active,
            menu.classes.current
          );
          let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
          subItem !== null && (subItem.style.display = "block");
        });
      } else {
        item.parentElement.classList.remove(
          menu.classes.active,
          menu.classes.current
        );
      }
    });
  };

  // dropdown toggle
  let dropdownToggle = function (elm) {
    let parent = elm.parentElement;
    let nextelm = elm.nextElementSibling;
    let speed =
      nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
    if (!parent.classList.contains(menu.classes.active)) {
      parent.classList.add(menu.classes.active);
      slideDown(nextelm, speed);
    } else {
      parent.classList.remove(menu.classes.active);
      slideUp(nextelm, speed);
    }
  };

  // dropdown close siblings
  let closeSiblings = function (elm) {
    let parent = elm.parentElement;
    let siblings = parent.parentElement.children;
    Array.from(siblings).forEach((item) => {
      if (item !== parent) {
        item.classList.remove(menu.classes.active);
        if (item.classList.contains(menu.classes.subparent)) {
          let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
          subitem.forEach((child) => {
            child.parentElement.classList.remove(menu.classes.active);
            slideUp(child, 400);
          });
        }
      }
    });
  };

  let menuToggle = function (e) {
    e.preventDefault();
    let item = e.target.closest(`.${menu.classes.toggle}`);
    dropdownToggle(item);
    closeSiblings(item);
  };

  useEffect(() => {
    currentLink(`.${menu.classes.link}`);
    // eslint-disable-next-line
  }, [null]);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ProfileDetails())
  }, [dispatch])

  const { data } = useSelector((state) => state.profile)
  return (
    <MenuList>
      {/* <MenuItem sub>
            <MenuItemLink icon="dashboard" text="Dashboard" onClick={menuToggle} sub/>
            <MenuSub>
                <MenuItem>
                    <MenuItemLink text="Default / Analytics" to="/home"/>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink text="eCommerce" to="/home-ecommerce"/>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink text="Project Manage" to="/home-project"/>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink text="Marketing" to="/home-marketing"/>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink text="NFT" to="/home-nft"/>
                </MenuItem>
            </MenuSub>
        </MenuItem> */}

      {/*NEW*/}

      {data.user_role === "admin" &&
        <MenuItem sub>
          <MenuItemLink
            icon="user-alt-fill"
            text="Masters"
            onClick={menuToggle}
            sub
          />
          <MenuSub>
            <MenuItem>
              <MenuItemLink
                icon="chart-down"
                text="User"
                to="/user-creation"
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                icon="navigate-fill"
                text="Route Master"
                to="/Router"
              />
            </MenuItem>

            <MenuItem sub>
              <MenuItemLink
                icon="user-alt-fill"
                text="Farmer Master"
                onClick={menuToggle}
                sub
              />
              <MenuSub>
                <MenuItem>
                  <MenuItemLink
                    icon="account-setting-alt"
                    text="Direct Farmer"
                    to="/farmer-view/direct"
                  />
                </MenuItem>
                <MenuItem>
                  <MenuItemLink
                    icon="account-setting-alt"
                    text="VLCC Farmer"
                    to="/farmer-view/vlcc"
                  />
                </MenuItem>
                <MenuItem>
                  <MenuItemLink
                    icon="account-setting-alt"
                    text="Collection Center"
                    to="/farmer-view/collection-center"
                  />
                </MenuItem>
              </MenuSub>
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                icon="chart-down"
                text="Rate-Chart Setting"
                to="/rate-chart"
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                icon="setting-fill"
                text="Collection Center Master"
                to="/master-view"
              />
            </MenuItem>
          </MenuSub>
        </MenuItem>}
      <MenuItem>
        <MenuItemLink
          icon="map"
          text="Plant Reception"
          to="/direct-vendor/active"
        />
      </MenuItem>
      <MenuItem>
        <MenuItemLink
          icon="map"
          text="VLCC Plant Reception"
          to="/vlcc-procurement/active"
        />
      </MenuItem>

      {/* Show these items only for admin, user or accounts role */}
      {(data.user_role === "admin" || data.user_role === "accounts" || data.user_role === "user") && (
        <>
          <MenuItem>
            <MenuItemLink
              icon="chart-down"
              text="Direct Collection"
              to="/overall-report/detail"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="chart-down"
              text="VLCC Collection"
              to="/vcc-list"
            />
          </MenuItem>
        </>
      )}

      <MenuItem sub>
        <MenuItemLink
          icon="user-alt-fill"
          text="Analyzer"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink
              icon="report"
              text="Reception Entry"
              to="/analyzer"

            />
          </MenuItem>
          {data.user_role === "admin" && <MenuItem>
            <MenuItemLink
              icon="report"
              text="Analyzer Log"
              to="/analyzer-log"
            />
          </MenuItem>}
          {data.user_role === "admin" && <MenuItem>
            <MenuItemLink
              icon="report"
              text="Analyzer Count"
              to="/analyzer-count"
            />
          </MenuItem>}
        </MenuSub>
      </MenuItem>

      {data.user_role === "admin" && <MenuItem>
        <MenuItemLink
          icon="report"
          text="Bank Master"
          to="/bank-master"
        />
      </MenuItem>}
      {data.user_role === "admin" && <MenuItem>
        <MenuItemLink
          icon="chat"
          text="Chat Master"
          to="/apps/chats"
        />
      </MenuItem>}
      {data.user_role === "admin" && <MenuItem>
        <MenuItemLink
          icon="report"
          text="Mobile Verification"
          to="/mobile-verification"
        />
      </MenuItem>}

      {data.user_role === "admin" && <MenuItem sub>
        <MenuItemLink
          icon="coins"
          text="Payment"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink
              text="Generate Payment"
              to="/generate-payment"
            />
          </MenuItem>
        </MenuSub>
      </MenuItem>}


      {data.user_role === "admin" && <MenuItem sub>
        <MenuItemLink icon="chart-down" text="Analytics" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink text="Filter Master" to="/filter-master" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink
              icon="chart-down"
              text="Day Summary"
              to="/day-summary"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="report"
              text="Comparison Report"
              to="/comparison"
            />
          </MenuItem>

        </MenuSub>
      </MenuItem>}

    </MenuList>
  );
}

export default Menu;
