import React, { useEffect, useRef } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Layout from "../../layout/fullpage";
import { Icon, } from "../../components";
import "./AuthRegister.css";
import FileUpload from "../../components/Form/FileUpload";
import { get, post } from "../masters/api/Api";
import Loader from "../masters/shared/Loader";
import { CodeDropDown } from "../masters/shared/dropDown/CodeDropDown";
import { useDispatch, useSelector } from "react-redux";
import { fetchFarmerList } from "../masters/store/store";

function AuthRegisterPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const farmerRef = useRef(null);
  const nameInputRef = useRef(null);
  const [centerId, setCenterId] = useState(null);
  const [centerCode, setCenterCode] = useState("");
  const centerRef = useRef(null);
  const [editData, setEditData] = useState([])
  const { id } = useParams()


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    mobile_no: '',
    email: '',
    role: '',
    center_id: null,
  });

  console.log(formData, "formData");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFarmerList());
  }, [dispatch]);

  const collectionCenter = useSelector((state) => state.directFarmerList);
  const filterCollection = collectionCenter.data?.filter((el) => el.type === "1");


  const fetchList = async () => {
    const url = `/api/direct-farmer/${id}/details`;
    try {
      const response = await get(url);
      if (response.status) {
        console.log("Server farmer response:", response.data);
        const data = response.data
        // const filteredData = data.filter((el) => el.id === parseFloat(id))
        // console.log("filteredData==>", filteredData);
        if (data) {
          setEditData(data)
        }
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  };

  console.log("editData", editData);

  useEffect(() => {
    if (parseFloat(id) !== 0) {
      fetchList();
    }
  }, [id])

  useEffect(() => {
    if (editData?.length !== 0 && parseFloat(id) !== 0) {
      const data = `${editData.code} - ${editData.name}`


      setFormData({
        role: "cc_user"
      })

      if (data) {
        setCenterCode(data)
      }
      if (editData.id) {
        setFormData({ ...formData, center_id: editData.id })
      }


    }
  }, [editData, id])





  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    if (errors[name]) {
      setErrors(prevState => ({
        ...prevState,
        [name]: null
      }));
    }
  };
  const handleRoleChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const nameValue = e.target.options[selectedIndex].getAttribute('value');
    setFormData(prevState => ({ ...prevState, role: nameValue }));
    if (errors.role) {
      setErrors(prevState => ({
        ...prevState,
        role: null
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Form data is:", formData);
      setLoading(true)
      const url = "/api/register";

      try {
        const response = await post(url, formData);
        console.log("Server response:", response);
        if (response.status) {
          setLoading(false)
          navigate('/farmer-view/collection-center')
        } else {
          setLoading(false)
        }


      } catch (error) {
        console.error("Error while posting data:", error);
        setLoading(false)
      }

    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Add your validation logic here, e.g:
    if (!formData.name) {
      formErrors.name = "Username is required.";
      isValid = false;
    }
    if (!formData.password) {
      formErrors.password = "Password is required.";
      isValid = false;
    }
    if (!formData.mobile_no) {
      formErrors.mobile_no = "Contact number is required.";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required.";
      isValid = false;
    }
    if (!formData.role) {
      formErrors.role = "Role is required.";
      isValid = false;
    }


    setErrors(formErrors);
    return isValid;
  };


  // Get Center Name and Center Code
  const handleInputCenter = (value, id, data) => {
    console.log(data, "data");
    setCenterCode(value);
    setCenterId(id);
    setFormData({ ...formData, center_id: id })

  };
  const handleCenter = (name) => {
    // setInputValue(name)
  };
  const handleFarmerCode = (value) => {
    // Simple validation check
    if (!value || value.trim() === "") {
      return "Farmer name with Code is required.";
    }
    return ""; // No error
  };


  return (
    <Layout title="Register" centered>
      <Loader loading={loading} />
      <Card>
        <Card.Body>
          <Row >

            <Col lg="3" className="mx-auto">

              <div className="nk-block-head text-center">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title mb-1">Create New Account</h3>
                  <p className="small">
                    Use Your Email To Continue With ASM Dairy
                  </p>
                </div>
              </div>
              <Card>
                <Card.Body>
                  <Form action="#" onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="username">Username</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="username"
                              name="name"
                              placeholder="Enter username"
                              value={formData.name}
                              onChange={handleInputChange}
                            />
                            {errors.name && <p className="text-danger">{errors.name}</p>}
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="contactnumber">
                            Password
                          </Form.Label>
                          <div className="form-control-wrap">
                            <div
                              className="form-control-icon end"
                              onClick={togglePasswordVisibility}
                            >
                              <Icon name={showPassword ? "eye-off" : "eye"} />{" "}
                            </div>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              id="exampleFormControlInputText4"
                              name="password"
                              placeholder="Enter The Password"
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                            {errors.password && <p className="text-danger">{errors.password}</p>}
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="contactnumber">
                            Contact Number
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="contactnumber"
                              name="mobile_no"
                              placeholder="Enter contact number "
                              value={formData.mobile_no}
                              maxLength={10}
                              onChange={handleInputChange}

                            />
                            {errors.mobile_no && <p className="text-danger">{errors.mobile_no}</p>}
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter email address"
                              value={formData.email}
                              onChange={handleInputChange}
                            />
                            {errors.email && <p className="text-danger">{errors.email}</p>}
                          </div>

                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="user_role">User Role</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              id="exampleFormControlInputText5"
                              aria-label="Default select example"
                              onChange={handleRoleChange}
                              value={formData.role}
                            >
                              <option value="0">Select a Role</option>
                              <option value="admin" name="admin" >Admin</option>
                              <option value="supervisor" name="supervisor">Supervisor</option>
                              <option value="user" name="user">User</option>
                              <option value="reception" name="reception">Reception</option>
                              <option value="lab" name="lab">Lab</option>
                              <option value="cc_user" name="center">Center</option>
                              <option value="accounts" name="accounts">Accounts</option>
                            </Form.Select>
                            {errors.role && <p className="text-danger">{errors.role}</p>}
                          </div>
                        </Form.Group>
                      </Col>

                      {formData.role === "cc_user" && <Col className="col-12">
                        <Form.Group className="form-group">
                          <CodeDropDown
                            data={filterCollection}
                            placeholder="Center Code"
                            name="Center Code"
                            nextInputRef={farmerRef}
                            onValidate={handleFarmerCode}
                            onSelect={(selectedItem, type, data, error) => {
                              handleInputCenter(selectedItem, type, data, error);
                            }}
                            // value={center}
                            func={handleCenter}
                            inputRef={centerRef}
                            style={{ width: "155px" }}
                            value={centerCode}

                          />
                        </Form.Group>
                      </Col>}



                      <Col sm="12">
                        <Form.Group className="form-group">
                          <Form.Label>Profile Picture</Form.Label>
                          <div className="form-control-wrap">
                            <FileUpload
                              iconName="files"
                              maxSize={4194304}
                              errorText="File size is too large, please upload file size within (4MB)"
                            />
                          </div>
                        </Form.Group>
                      </Col>


                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit">Sign up</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>

                </Card.Body>
              </Card>

            </Col>

          </Row>
        </Card.Body>
      </Card>

    </Layout>
  );
}

export default AuthRegisterPage;
