import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ProfileDetails } from '../../pages/masters/store/store';

function Logo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: profileData } = useSelector((state) => state.profile);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !profileData) {
      dispatch(ProfileDetails());
    }
  }, [dispatch, profileData]);

  const handleLogoClick = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');

    if (!token) {
      navigate('/auth-login');
      return;
    }

    if (userRole === 'admin' || profileData?.user_role === 'admin') {
      navigate('/home');
    } else {
      navigate('/');
    }
  };

  return (
    <Link to="#" className="logo-link" onClick={handleLogoClick}>
      <div className="logo-wrap">
        <img src={"/images/asmlogo.png"} alt="" className="logo-image" style={{ width: '50px', height: '50px' }} />

        <span style={{ fontSize: '19px ', marginLeft: '10px', marginTop: '10px', marginBottom: '20px', color: 'white' }}>ASM Dairy</span>
      </div>
    </Link>
  );
}

export default Logo;
