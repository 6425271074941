import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";

import { ScrollToTop } from "../components";

//Pages
import Blank from "../pages/Blank";
import Home from "../pages/Home";
import HomeEcommerce from "../pages/HomeEcommerce";
import HomeProject from "../pages/HomeProject";
import HomeMarketing from "../pages/HomeMarketing";
import HomeNFT from "../pages/HomeNFT";

import CenterSetting from "../pages/masters/centerSetting";

import FarmerCreationFields from "../pages/masterFields/FarmerCreationField";
import RouteMasterFields from "../pages/masterFields/RouteMasterFields";
/* import VehicleMaster from "../pages/masters/VehicleMaster";
import VehicleMasterField from "../pages/masterFields/VehicleMasterField"; */
import { UserRole } from "../pages/masters/view/user/UserRole";
import CollectionSettingNew from "../pages/defaultMasters/CollectionSettingNew";


//Default Settings
// import CollectionSettings from "../pages/defaultMasters/CollectionSettings";

// apps
import AppCalendar from "../pages/AppCalendar";
import KanbanBasic from "../pages/kanban/KanbanBasic";
import KanbanCustom from "../pages/kanban/KanbanCustom";
import Chats from "../pages/apps/chat/Chats";
import Inbox from "../pages/apps/mailbox/Inbox";

// user manage
import UserList from "../pages/user-manage/UserList";
import UserCards from "../pages/user-manage/UserCards";
import UserProfile from "../pages/user-manage/UserProfile";
import UserEdit from "../pages/user-manage/UserEdit";

// admin
import Profile from "../pages/admin/Profile";
import ProfileSettings from "../pages/admin/ProfileSettings";

// ecommerce
import Products from "../pages/ecommerce/Products";
import Categories from "../pages/ecommerce/Categories";
import AddProduct from "../pages/ecommerce/AddProduct";
import EditProduct from "../pages/ecommerce/EditProduct";
import AddCategory from "../pages/ecommerce/AddCategory";
import EditCategory from "../pages/ecommerce/EditCategory";

// ui elements
import Accordion from "../pages/components/Accordion";
import Alert from "../pages/components/Alert";
import Badge from "../pages/components/Badge";
import Breadcrumb from "../pages/components/Breadcrumb";
import Buttons from "../pages/components/Buttons";
import ButtonGroup from "../pages/components/ButtonGroup";
import Cards from "../pages/components/Cards";
import Carousel from "../pages/components/Carousel";
import CloseButton from "../pages/components/CloseButton";
import Collapse from "../pages/components/Collapse";
import Dropdowns from "../pages/components/Dropdowns";
import ListGroup from "../pages/components/ListGroup";
import Modal from "../pages/components/Modal";
import Tabs from "../pages/components/Tabs";
import Offcanvas from "../pages/components/Offcanvas";
import Pagination from "../pages/components/Pagination";
import Placeholders from "../pages/components/Placeholders";
import Popovers from "../pages/components/Popovers";
import Progress from "../pages/components/Progress";
import Spinners from "../pages/components/Spinners";
import Toasts from "../pages/components/Toasts";
import Tooltips from "../pages/components/Tooltips";

// utilities
import Misc from "../pages/utilities/Misc";
import Typography from "../pages/utilities/Typography";
import Images from "../pages/utilities/Images";
import Tables from "../pages/utilities/Tables";
import Background from "../pages/utilities/Background";
import Borders from "../pages/utilities/Borders";
import Colors from "../pages/utilities/Colors";
import Flex from "../pages/utilities/Flex";
import Sizing from "../pages/utilities/Sizing";
import Spacing from "../pages/utilities/Spacing";

// layout
import Breakpoints from "../pages/layout/Breakpoints";
import Containers from "../pages/layout/Containers";
import Gutters from "../pages/layout/Gutters";
// forms
import FormControl from "../pages/forms/FormControl";
import FormSelect from "../pages/forms/FormSelect";
import DateTime from "../pages/forms/DateTime";
import FormUpload from "../pages/forms/FormUpload";
import InputGroup from "../pages/forms/InputGroup";
import FloatingLabels from "../pages/forms/FloatingLabels";
import ChecksRadios from "../pages/forms/ChecksRadios";
import FormRange from "../pages/forms/FormRange";
import FormValidation from "../pages/forms/FormValidation";
import FormLayout from "../pages/forms/FormLayout";
import QuillPreview from "../pages/forms/editors/QuillPreview";
import TinymcePreview from "../pages/forms/editors/TinymcePreview";

// other pages
import DataTablePreview from "../pages/DataTablePreview";
import ChartsPreview from "../pages/ChartsPreview";
import Sweetalert from "../pages/Sweetalert";

// auths pages
import AuthRegister from "../pages/auths/AuthRegister";
import AuthLogin from "../pages/auths/AuthLogin";
import AuthReset from "../pages/auths/AuthReset";

import NotFound from "../pages/error/NotFound";
import IconsPreview from "../pages/IconsPreview";

import MenuView from "../pages/masters/view/MenuView";
import { RoleView } from "../pages/masters/view/RoleView";
import { NewRole } from "../pages/masters/view/NewRole";
import { AuthProvider } from "../context/AuthProvider";
import { ProtectedRoute } from "../protected-route/ProtectedRoute";

import { Report } from "../pages/masters/procurement/Report";


import { CenterView } from "../pages/masters/view/center/CenterView";
import { CenterMasterView } from "../pages/masters/view/center/CenterMasterView";
import { VehicleView } from "../pages/masters/view/VehicleView";
import { VlccEntry } from "../pages/masters/procurement/vlcc/VlccEntry";
import AllEntryView from "../pages/masters/procurement/AllEntryView";
import ListView from "../pages/masters/farmer-list/ListView";
import VlccList from "../pages/masters/procurement/vlcc/VlccList";
import VlccForm from "../pages/masters/procurement/vlcc/VlccForm";
import SettingsViewPage from "../pages/masters/viewpage/SettingsViewPage";
import CollectionCenter from "../pages/masters/CollectionCenter";
import { toast } from "react-toastify";
import VlccProcForm from "../pages/masters/procurement/vlcc/VlccProcForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ProfileDetails } from "../pages/masters/store/store";
import Welcome from "../components/Welcome/Welcome";

// Main View Path

const RouteMaster = lazy(() => import("../pages/masters/RouteMaster"));
const FarmerCreation = lazy(() => import("../pages/masters/farmerCreations"));
const FarmerView = lazy(() => import("../pages/masters/view/farmer/FarmerView"));
const RateChart = lazy(() => import("../pages/masters/rate-chart/RateChart"));
const ProcurementProcess = lazy(() => import("../pages/masters/procurement/ProcurementProcess"));
const ProcurementForm = lazy(() => import("../pages/masters/procurement/direct/ProcurementForm"));
const ProcurementList = lazy(() => import("../pages/masters/procurement/direct/ProcurementList"));
const BulkProcurement = lazy(() => import("../pages/masters/procurement/BulkProcurement"));
const DirectProcurement = lazy(() => import("../pages/masters/procurement/direct/DirectProcurement"));
const ReportPage = lazy(() => import("../pages/masters/report/Report"));
const Voucher = lazy(() => import("../pages/masters/voucher/Voucher"));
const OverAllReport = lazy(() => import("../pages/masters/report/OverAllReport"));
const Users = lazy(() => import("../pages/masters/view/user/UserList"));
const UserCreation = lazy(() => import("../pages/masters/view/user/UserCreation"));
const VccFarmer = lazy(() => import("../pages/masters/VccFarmer"));
const ProfilePage = lazy(() => import("../pages/masters/profile/ProfileDetails"))
const VLCCProfilePage = lazy(() => import("../pages/masters/profile/VlccProfileDetails"))
const VccForm = lazy(() => import("../pages/masters/procurement/vlcc/VlccForm"));
const CollectionSetting = lazy(() => import("../pages/masters/settings/CollectionSetting"))
const CollectionSettingView = lazy(() => import("../pages/masters/viewpage/CollectionSettingView"))
const VehicleMaster = lazy(() => import("../pages/masters/settings/VehicleMaster"))
const DriverMaster = lazy(() => import("../pages/masters/settings/DriverMaster"))
const TripCreation = lazy(() => import("../pages/masters/settings/TripCreation"))
const CanMaster = lazy(() => import("../pages/masters/settings/CanMaster"))
const PrinterSetting = lazy(() => import("../pages/masters/settings/PrinterSetting"))
const EquipmentSetting = lazy(() => import("../pages/masters/settings/EquipmentSetting"))
const AnalyserSetting = lazy(() => import("../pages/masters/settings/AnalyserSetting"))
const AnalyserMasterView = lazy(() => import("../pages/masters/viewpage/AnalyserMasterView"))
const EquipmentSettingView = lazy(() => import("../pages/masters/viewpage/EquipmentSettingView"))
const VehicleMasterView = lazy(() => import("../pages/masters/viewpage/VehicleMasterView"))
const DriverMasterView = lazy(() => import("../pages/masters/viewpage/DriverMasterView"))
const PrinterSettingView = lazy(() => import("../pages/masters/viewpage/PrinterSettingView"))
const CanMasterView = lazy(() => import("../pages/masters/viewpage/CanMasterView"))
const VlccProcurement = lazy(() => import("../pages/masters/procurement/vlcc/VlccProcurement"))

// const EquipmentSetting = lazy(() => import("../pages/masters/settings/CollectionSetting"))
const Analyzer = lazy(() => import("../pages/masters/procurement/analyzer/Analyzer"))
const AnalyzerLog = lazy(() => import("../pages/masters/procurement/analyzer/AnalyzerLog"))
const AnalyzerCount = lazy(() => import("../pages/masters/procurement/analyzer/AnalyzerCount"))
const DaySummary = lazy(() => import("../pages/masters/report/DaySummary"))
const Attendence = lazy(() => import("../pages/masters/report/Attendence"))
const PaymentModule = lazy(() => import("../pages/masters/payment-module/PaymentModule"))
const Comparison = lazy(() => import("../pages/masters/report/ComparisonReport"))
const BankMaster = lazy(() => import("../pages/masters/bank-masters/BankMaster"))
const BankList = lazy(() => import("../pages/masters/bank-masters/BankList"))
const FilterMaster = lazy(() => import("../pages/masters/filter/filter-create"))
const FilterList = lazy(() => import("../pages/masters/filter/filter-list"))

const MobileVerification = lazy(() => import("../components/MobileVerification/MobileVerification"))

const GeneratePayment = lazy(() => import("../pages/masters/payment-module/GeneratePayment"))


function Router() {

  const navigate = useNavigate();
  const timeoutIdRef = useRef(null);
  const dispatch = useDispatch();
  const [isSlowConnection, setIsSlowConnection] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [hasRedirected, setHasRedirected] = useState(false);
  const location = useLocation();

  // Get profile data from Redux store
  const { data: profileData } = useSelector((state) => state.profile);

  // Fetch profile details when component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(ProfileDetails());
    }
  }, [dispatch]);


  console.log("profileData", profileData)


  // // Handle role-based navigation with proper conditions
  // useEffect(() => {
  //   const handleRoleBasedNavigation = () => {
  //     const token = localStorage.getItem('token');
  //     const currentPath = location.pathname;

  //     if (
  //       token &&
  //       profileData?.user_role === 'user' &&
  //       !hasRedirected &&
  //       currentPath !== '/direct-vendor/active' &&
  //       !['/auth-login', '/auth-register'].includes(currentPath)
  //     ) {
  //       setHasRedirected(true);
  //       navigate('/direct-vendor/active', { replace: true });
  //     }
  //   };

  //   if (profileData) {
  //     handleRoleBasedNavigation();
  //   }

  //   // Cleanup function
  //   return () => {
  //     setHasRedirected(false);
  //   };
  // }, [profileData, navigate, location.pathname, hasRedirected]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success('Internet connection is back!');
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.error('No internet connection!');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab is inactive, start a timeout for 1 minute
        console.log('Tab is inactive. Starting logout timeout.');
        timeoutIdRef.current = setTimeout(() => {
          // remove token in localStorage
          localStorage.removeItem('token')
          // and then navigate to the login page
          navigate('/auth-login');

        }, 1200000); // 1 minute (60,000 milliseconds)
      } else {
        // Tab is active, clear the timeout
        clearTimeout(timeoutIdRef.current);
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // Clear the timeout to prevent any logout after unmounting
      clearTimeout(timeoutIdRef.current);
    };
  }, [navigate]);



  useEffect(() => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
      const handleNetworkChange = () => {
        const { effectiveType } = connection;
        // "slow-2g", "2g", "3g", or "4g"
        setIsSlowConnection(effectiveType === 'slow-2g' || effectiveType === '2g');
      };
      connection.addEventListener('change', handleNetworkChange);

      return () => {
        connection.removeEventListener('change', handleNetworkChange);
      };
    }
  }, []);

  useEffect(() => {
    if (isSlowConnection) {
      toast.warning('Your internet connection is slow. Please consider switching to a faster network.');
    }
  }, [isSlowConnection]);


  useEffect(() => {
    const token = localStorage.getItem('token');
    // Set up a listener for token changes
    const handleTokenChange = () => {
      if (!token) navigate('/auth-login')
    };
    console.log("token****", token);
    window.addEventListener('storage', handleTokenChange);

    return () => {
      window.removeEventListener('storage', handleTokenChange);
    };
  }, [navigate]);


  return (
    <Suspense >
      <AuthProvider>
        <ScrollToTop>
          <Routes>
            <Route path="auth-register/:id" element={<AuthRegister />} />
            <Route path="auth-login" element={<AuthLogin />} />

            <Route path="blank" element={<Blank />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  {profileData?.user_role === 'user' ?
                    <Welcome />
                    :
                    <Home />
                  }

                </ProtectedRoute>
              }
            />

            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  {profileData?.user_role === 'user' ?
                    <Welcome />
                    :
                    <Home />
                  }

                </ProtectedRoute>
              }
            />

            <Route path="home-ecommerce" element={<HomeEcommerce />} />
            <Route path="home-project" element={<HomeProject />} />
            <Route path="home-marketing" element={<HomeMarketing />} />
            <Route path="home-nft" element={<HomeNFT />} />

            {/* <Route path="PrinterSetting" element={<PrinterSetting />} /> */}
            <Route path="EquipmentSetting" element={<EquipmentSetting />} />
            {/* <Route path="CollectionSetting" element={<CollectionSetting />} /> */}
            <Route path="CollectionSettingNew" element={<CollectionSettingNew />} />
            <Route path="CenterSetting" element={<CenterSetting />} />

            <Route path="farmer-field" element={<FarmerCreationFields />} />
            <Route path="route-field" element={<RouteMasterFields />} />
            {/*<Route path="VehicleMaster" element={<VehicleMaster />} />
            <Route path="Vehicle-Field" element={<VehicleMasterField />} />
 */}
            <Route path="view" element={<UserRole />} />
            <Route path="menu-view" element={<MenuView />} />
            <Route path="role-view" element={<RoleView />} />
            <Route path="add-role/:id" element={<NewRole />} />
            <Route path="center-view" element={<CenterView />} />
            <Route path="master-view" element={<CenterMasterView />} />
            <Route path="vehicle-view" element={<VehicleView />} />
            <Route path="vlcc-entry" element={<VlccEntry />} />

            {/* Main Component /> */}

            <Route path="Router" element={<ProtectedRoute><RouteMaster /></ProtectedRoute>} />
            <Route path="FarmerCreations/:id" element={<ProtectedRoute><FarmerCreation /></ProtectedRoute>} />
            <Route path="farmer-view/:id" element={<ProtectedRoute><FarmerView /></ProtectedRoute>} />
            <Route path="overall-report/:id" element={<ProtectedRoute><OverAllReport /></ProtectedRoute>} />
            <Route path="rate-chart" element={<ProtectedRoute><RateChart /></ProtectedRoute>} />
            <Route path="procurement-form/:id/:action" element={<ProtectedRoute><ProcurementForm /></ProtectedRoute>} />
            <Route path="vlccProc-form/:id/:cId/:accessId" element={<ProtectedRoute><VlccProcForm /></ProtectedRoute>} />
            <Route path="procurement" element={<ProtectedRoute><ProcurementProcess /></ProtectedRoute>} />
            <Route path="direct-vendor/:type" element={<ProtectedRoute><DirectProcurement /></ProtectedRoute>} />
            <Route path="pro-list/:id/:rId" element={<ProtectedRoute><ProcurementList /></ProtectedRoute>} />
            <Route path="report" element={<Report />} />
            <Route path="bulk-vendor" element={<ProtectedRoute><BulkProcurement /></ProtectedRoute>} />
            <Route path="overall" element={<AllEntryView />} />
            <Route path="vcc-list" element={<ProtectedRoute><VlccList /></ProtectedRoute>} />
            <Route path="vcc-form" element={<ProtectedRoute><VccForm /></ProtectedRoute>} />
            <Route path="entry-report" element={<ProtectedRoute><ReportPage /></ProtectedRoute>} />
            <Route path="voucher" element={<ProtectedRoute><Voucher /></ProtectedRoute>} />

            <Route path="user-list" element={<ProtectedRoute><Users /></ProtectedRoute>} />
            <Route path="user-creation" element={<ProtectedRoute><UserCreation /></ProtectedRoute>} />
            <Route path="vcc-farmer/:id" element={<ProtectedRoute><VccFarmer /></ProtectedRoute>} />
            <Route path="profile-page/:id/:type" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="vlcc-profile/:id/:type" element={<ProtectedRoute><VLCCProfilePage /></ProtectedRoute>} />
            <Route path="collection-setting" element={<ProtectedRoute><CollectionSetting /></ProtectedRoute>} />
            <Route path="CollectionSetting-View" element={<ProtectedRoute><CollectionSettingView /></ProtectedRoute>} />
            <Route path="Analyser-Setting" element={<ProtectedRoute><AnalyserSetting /></ProtectedRoute>} />
            <Route path="AnalyserMaster-View" element={<ProtectedRoute><AnalyserMasterView /></ProtectedRoute>} />
            <Route path="vehicle-master" element={<ProtectedRoute><VehicleMaster /></ProtectedRoute>} />
            <Route path="Driver-Master" element={<ProtectedRoute><DriverMaster /></ProtectedRoute>} />
            <Route path="trip-creation" element={<ProtectedRoute><TripCreation /></ProtectedRoute>} />
            <Route path="can-master" element={<ProtectedRoute><CanMaster /></ProtectedRoute>} />
            <Route path="Printer-Setting" element={<ProtectedRoute><PrinterSetting /></ProtectedRoute>} />
            <Route path="Equipment-Setting" element={<ProtectedRoute><EquipmentSetting /></ProtectedRoute>} />
            <Route path="EquipmentSetting-View" element={<ProtectedRoute><EquipmentSettingView /></ProtectedRoute>} />
            <Route path="VehicleMaster-View" element={<ProtectedRoute><VehicleMasterView /></ProtectedRoute>} />
            <Route path="DriverMaster-View" element={<ProtectedRoute><DriverMasterView /></ProtectedRoute>} />
            <Route path="PrinterSetting-View" element={<ProtectedRoute><PrinterSettingView /></ProtectedRoute>} />
            <Route path="CanMaster-View" element={<ProtectedRoute><CanMasterView /></ProtectedRoute>} />
            <Route path="analyzer" element={<ProtectedRoute><Analyzer /></ProtectedRoute>} />
            <Route path="analyzer-log" element={<ProtectedRoute><AnalyzerLog /></ProtectedRoute>} />
            <Route path="day-summary" element={<ProtectedRoute><DaySummary /></ProtectedRoute>} />
            <Route path="settings-view/:url/:id" element={<ProtectedRoute><SettingsViewPage /></ProtectedRoute>} />
            <Route path="collection-center/:id" element={<ProtectedRoute><CollectionCenter /></ProtectedRoute>} />
            <Route path="analyzer-count" element={<ProtectedRoute><AnalyzerCount /></ProtectedRoute>} />
            <Route path="attendence" element={<ProtectedRoute><Attendence /></ProtectedRoute>} />
            <Route path="vlcc-procurement/:type" element={<ProtectedRoute><VlccProcurement /></ProtectedRoute>} />
            <Route path="payment-module" element={<ProtectedRoute><PaymentModule /></ProtectedRoute>} />
            <Route path="comparison" element={<ProtectedRoute><Comparison /></ProtectedRoute>} />
            <Route path="bank-master" element={<ProtectedRoute><BankMaster /></ProtectedRoute>} />
            <Route path="bank-list" element={<ProtectedRoute><BankList /></ProtectedRoute>} />
            <Route path="filter-master" element={<ProtectedRoute><FilterMaster /></ProtectedRoute>} />
            <Route path="filter-list" element={<ProtectedRoute><FilterList /></ProtectedRoute>} />
            <Route path="mobile-verification" element={<ProtectedRoute><MobileVerification /></ProtectedRoute>} />
            <Route path="generate-payment" element={<ProtectedRoute><GeneratePayment /></ProtectedRoute>} />

            <Route path="apps">
              <Route path="calendar" element={<AppCalendar />} />
              <Route path="kanban/basic" element={<KanbanBasic />} />
              <Route path="kanban/custom" element={<KanbanCustom />} />
              <Route path="chats" element={<Chats />} />
              <Route path="inbox" element={<Inbox />} />
            </Route>

            <Route path="user-manage">
              <Route path="user-list" element={<UserList />} />
              <Route path="user-cards" element={<UserCards />} />
              <Route path="user-profile/:id" element={<UserProfile />} />
              <Route path="user-edit/:id" element={<UserEdit />} />
            </Route>

            <Route path="admin">
              <Route path="profile" element={<Profile />} />
              <Route path="profile-settings" element={<ProfileSettings />} />
            </Route>

            <Route path="ecommerce">
              <Route path="products" element={<Products />} />
              <Route path="categories" element={<Categories />} />
              <Route path="add-product" element={<AddProduct />} />
              <Route path="edit-product/:id" element={<EditProduct />} />
              <Route path="add-category" element={<AddCategory />} />
              <Route path="edit-category/:id" element={<EditCategory />} />
            </Route>

            <Route path="ui-elements">
              <Route path="accordion" element={<Accordion />} />
              <Route path="alert" element={<Alert />} />
              <Route path="badge" element={<Badge />} />
              <Route path="breadcrumb" element={<Breadcrumb />} />
              <Route path="buttons" element={<Buttons />} />
              <Route path="button-group" element={<ButtonGroup />} />
              <Route path="cards" element={<Cards />} />
              <Route path="carousel" element={<Carousel />} />
              <Route path="close-button" element={<CloseButton />} />
              <Route path="collapse" element={<Collapse />} />
              <Route path="dropdowns" element={<Dropdowns />} />
              <Route path="list-group" element={<ListGroup />} />
              <Route path="modal" element={<Modal />} />
              <Route path="tabs" element={<Tabs />} />
              <Route path="offcanvas" element={<Offcanvas />} />
              <Route path="pagination" element={<Pagination />} />
              <Route path="placeholders" element={<Placeholders />} />
              <Route path="popovers" element={<Popovers />} />
              <Route path="progress" element={<Progress />} />
              <Route path="spinners" element={<Spinners />} />
              <Route path="toasts" element={<Toasts />} />
              <Route path="tooltips" element={<Tooltips />} />
            </Route>

            <Route path="utilities">
              <Route path="misc" element={<Misc />} />
              <Route path="typography" element={<Typography />} />
              <Route path="images" element={<Images />} />
              <Route path="tables" element={<Tables />} />
              <Route path="background" element={<Background />} />
              <Route path="borders" element={<Borders />} />
              <Route path="colors" element={<Colors />} />
              <Route path="flex" element={<Flex />} />
              <Route path="sizing" element={<Sizing />} />
              <Route path="spacing" element={<Spacing />} />
            </Route>

            <Route path="layout">
              <Route path="breakpoints" element={<Breakpoints />} />
              <Route path="containers" element={<Containers />} />
              <Route path="gutters" element={<Gutters />} />
            </Route>

            <Route path="forms">
              <Route path="form-control" element={<FormControl />} />
              <Route path="form-select" element={<FormSelect />} />
              <Route path="date-time" element={<DateTime />} />
              <Route path="form-upload" element={<FormUpload />} />
              <Route path="input-group" element={<InputGroup />} />
              <Route path="floating-labels" element={<FloatingLabels />} />
              <Route path="checks-radios" element={<ChecksRadios />} />
              <Route path="form-range" element={<FormRange />} />
              <Route path="form-validation" element={<FormValidation />} />
              <Route path="form-layout" element={<FormLayout />} />
            </Route>

            <Route path="editors">
              <Route path="quill" element={<QuillPreview />} />
              <Route path="tinymce" element={<TinymcePreview />} />
            </Route>

            <Route path="data-table" element={<DataTablePreview />} />
            <Route path="charts" element={<ChartsPreview />} />
            <Route path="sweetalert" element={<Sweetalert />} />

            <Route path="auths">

              <Route path="auth-reset" element={<AuthReset />} />
            </Route>

            <Route path="icons" element={<IconsPreview />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </ScrollToTop>
      </AuthProvider>
    </Suspense>
  );
}

export default Router;