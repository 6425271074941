import React from "react";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Layout from "../../layout/fullpage";
import { Logo, Icon } from "../../components";
import { useAuth } from "../../context/AuthProvider";
import { loginPost } from "../masters/api/Api";
import { toast } from "react-toastify";


const AuthLoginPage = () => {

  const { login } = useAuth();
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  //Password Start
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    user_email: '',
    password: '',

  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    if (errors[name]) {
      setErrors(prevState => ({
        ...prevState,
        [name]: null
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const url = "/api/login";
      try {
        setIsButtonDisabled(true)
        const response = await loginPost(url, formData);
        if (response.status) {
          localStorage.setItem("token", response.authorisation.token);
          localStorage.setItem("role", response.data.user_role);
          toast.success("Login successful")
          navigate("/home")
        }

      } catch (error) {
        console.error("Error while posting data:", error);
      } finally {
        setIsButtonDisabled(false)
      }

    }
  };
  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Add your validation logic here, e.g:
    if (!formData.user_email) {
      formErrors.user_email = "ID is required.";
      isValid = false;
    }
    if (!formData.password) {
      formErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  //password Exit


  const logo = require("../../assets/images/mask/ASM.png")

  return (
    <>
      <Layout title="Login" centered>



        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="5">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title mb-1">Login to Account</h3>
                      <p className="small">Please sign-in to your account</p>
                    </div>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="userId">ID</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="userId"
                              name="user_email"
                              placeholder="Enter ID"
                              value={formData.user_email}
                              onChange={handleInputChange}
                            />
                            {errors.user_email && <p className="text-danger">{errors.user_email}</p>}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="contactnumber">
                            Password
                          </Form.Label>
                          <div className="form-control-wrap">
                            <div
                              className="form-control-icon end"
                              onClick={togglePasswordVisibility}
                            >
                              <Icon name={showPassword ? "eye-off" : "eye"} />{" "}
                              {/* Replace with the actual icon names */}
                            </div>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              id="exampleFormControlInputText4"
                              placeholder="Enter The Password"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                            {errors.password && <p className="text-danger">{errors.password}</p>}

                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">

                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit" disabled={isButtonDisabled}>
                            {isButtonDisabled && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                            <span className="ms-1">Login to account</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>


                </Card.Body>
              </Col>
              <Col lg="7">
                <Card.Body className="bg-dark is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                  <div className="mask mask-1"></div>
                  <div className="brand-logo">
                    <Logo />
                  </div>
                  <div className="row">
                    <div className="col-sm-11">
                      <div className="mt-4">
                        <div className="h1 title mb-3">
                          Welcome back to <br /> our community
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">

                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default AuthLoginPage;


