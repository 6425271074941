import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import exportFromJSON from 'export-from-json'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTablePagination from '../Pagination/DataTablePagination';
import Icon from '../Icon/Icon';
import 'jspdf-autotable';
import { useReactToPrint } from 'react-to-print';
import CustomDataTable from '../../pages/masters/shared/printer/PrintDataTable';
import { dateReverseH, dateReverseHM, dateSplit, extractDateAndTimeFromTimestamp, extractDateFromTimestamp } from '../../pages/masters/shared/Date';
import './Table.css'
import { useDispatch } from 'react-redux';
import { ProfileDetails } from '../../pages/masters/store/store';
import { useSelector } from 'react-redux';
import { postToast } from '../../pages/masters/api/Api';
import ExpandedComponent from './ExpandedComponent';
import { createTextFile } from '../../pages/masters/shared/TextFile';

// export file component
export const Export = ({ listData, entryList, codeType, filterValue, type, tab }) => {

  let shouldShowButton = false;
  console.log("[[data]]", listData, filterValue);

  console.log("tab", tab)

  if (filterValue?.mode_of_payment?.includes(1)) {

    const find = listData?.every(item => {
      const modePaymentInfo = typeof item.mode_payment_info === 'string' ? JSON.parse(item.mode_payment_info) : item.mode_payment_info;
      return modePaymentInfo?.[0]?.mode_of_payment === 1;
    });
    console.log("find", find);
    shouldShowButton = find

  } else if (filterValue?.mode_payment?.includes(1)) {
    const find = listData?.every(item => JSON.parse(item?.mode_payment_info)?.[0]?.mode_of_payment === 1);
    shouldShowButton = find
  }

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    const fileName = "Farmer List";

    if (entryList) {
      const fileName = "Plant entry List";
      // Transform the data into the desired format
      const transformedData = listData?.map(item => ({

        "Route": `${codeType ? item.route_code : item.code} - ${item.route_name}`,
        "Date": dateSplit(item.bill_date),
        "Shift": item.session,
        "Bill No": item.bill_no,
        "Code": item.farmer_code,
        "Name": item.farmer_name,
        "Fat": (item.tot_fat).toFixed(2),
        "SNF": (item.tot_snf).toFixed(2),
        // "Kgs": (item.tot_qty_kg).toFixed(2),
        "LTR": (item.tot_qty_litre).toFixed(2),
        "Rate": (item.tot_rate).toFixed(2),
        "Amount": (item.tot_amount).toFixed(2)
      }));

      // Export the transformed data to Excel
      exportFromJSON({ data: transformedData, fileName, exportType });
    }

    if (!entryList) {
      const farmerList = listData.map((item) => ({
        "PYMT_PROD_TYPE_CODE": "PAB_VENDOR",
        "PYMT_MODE": "NEFT",
        "DEBIT_ACC_NO": "",
        "BNF_NAME": item.name,
        "BENE_ACC_NO": "'" + item?.mode_payment_info?.[0]?.bank_info?.AccNo || "",
        "BENE_IFSC": item?.mode_payment_info?.[0]?.bank_info?.ifsc,
        "AMOUNT": "1.00",
        "DEBIT_NARR": "",
        "CREDIT_NARR": "",
        "MOBILE_NUM": item?.mobile_no,
        "EMAIL_ID": item?.communication_info?.[0]?.email ? item?.communication_info?.[0]?.email : "admin@asmdairy.com",
        "REMARK": "",
        "PYMT_DATE": dateReverseH(extractDateFromTimestamp(new Date())),
        "REF_NO": "",
        "ADDL_INFO1": "",
        "ADDL_INFO2": "",
        "ADDL_INFO3": "",
        "ADDL_INFO4": "",
        "ADDL_INFO5": "",
      }))

      exportFromJSON({ data: farmerList, fileName, exportType });
    }
  };

  const fileName = "Farmer List";
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    const fileName = `asm_${dateReverseHM(extractDateAndTimeFromTimestamp(new Date()))}`;

    if (entryList) {

      const fileName = "Plant entry List";
      // Transform the data into the desired format
      const transformedData = listData.map(item => ({
        "Route": `${codeType ? item.route_code : item.code} - ${item.route_name}`,
        "Date": item.bill_date,
        "Shift": item.session,
        "Bill No": item.bill_no,
        "Code": item.farmer_code,
        "Name": item.farmer_name,
        "Fat": (item.tot_fat).toFixed(2),
        "SNF": (item.tot_snf).toFixed(2),
        // "Kgs": (item.tot_qty_kg).toFixed(2),
        "LTR": (item.tot_qty_litre).toFixed(2),
        "Rate": (item.tot_rate).toFixed(2),
        "Amount": (item.tot_amount).toFixed(2)
      }));

      // Export the transformed data to Excel
      exportFromJSON({ data: transformedData, fileName, exportType });
    }

    if (!entryList) {
      const farmerList = listData.map((item) => ({
        "PYMT_PROD_TYPE_CODE": "PAB_VENDOR",
        "PYMT_MODE": "NEFT",
        "DEBIT_ACC_NO": "",
        "BNF_NAME": item.name,
        "BENE_ACC_NO": "'" + item?.mode_payment_info?.[0]?.bank_info?.AccNo || "",
        "BENE_IFSC": item?.mode_payment_info?.[0]?.bank_info?.ifsc,
        "AMOUNT": "1.00",
        "DEBIT_NARR": "",
        "CREDIT_NARR": "",
        "MOBILE_NUM": item?.mobile_no,
        "EMAIL_ID": item?.communication_info?.[0]?.email ? item?.communication_info?.[0]?.email : "admin@asmdairy.com",
        "REMARK": "",
        "PYMT_DATE": dateReverseH(extractDateFromTimestamp(new Date())),
        "REF_NO": "",
        "ADDL_INFO1": "",
        "ADDL_INFO2": "",
        "ADDL_INFO3": "",
        "ADDL_INFO4": "",
        "ADDL_INFO5": "",
      }))
      exportFromJSON({ data: farmerList, fileName, exportType });
    }
  };

  const filtered_list = async (body) => {
    const url = `/api/collection-report/generate-payment`;

    try {
      const response = await postToast(url, body);
      if (response.status) {
        console.log("Server farmer response:", response.data.data);
        const getData = response.data.data
        // createTextFile(data)
        const headData = response.summary
        createTextFile(getData, body, headData)
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  };

  const filtered_list_vlcc = async (body) => {
    const url = `/api/vcc-collection/list`;

    try {
      const response = await postToast(url, body);
      if (response.status) {
        console.log("Server farmer response:", response.data.data);
        const getData = response.data.data
        // createTextFile(data)
        const headData = response.summary
        createTextFile(getData, body, headData)
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  };
  let body
  const textFileFormat = () => {

    if (type !== "VLCC") {
      body = {
        from_date: filterValue?.from_date,
        from_session: filterValue?.from_session,
        to_date: filterValue?.to_date,
        to_session: filterValue?.to_session,
        route_id: filterValue.route_id,
        mode_of_payment: filterValue.mode_of_payment,
        payment_cycle: filterValue.payment_cycle,
        farmer_id: filterValue.farmer_id,
        farmer_start_code: filterValue.farmer_start_code,
        farmer_end_code: filterValue.farmer_end_code,
        page_count: 1000,
        page: 1,
        route: filterValue.route
      }
      filtered_list(body)
    } else if (type === "VLCC") {
      body = {
        from_date: filterValue?.startDate,
        from_session: filterValue?.sessionDay,
        to_date: filterValue?.endDate,
        to_session: filterValue?.sessionNight,
        route_id: filterValue.route_id,
        center_id: filterValue?.center_id,
        mode_of_payment: filterValue.mode_payment,
        page_count: 1000,
        page: 1,
        route: filterValue.route_details,
        center_details: filterValue.center_details,
        type: type
      }
      filtered_list_vlcc(body)
    }

  }

  return (
    <div>
      <>
        {(shouldShowButton && !entryList) && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-excel">Export to Excel</Tooltip>}
          >
            <Button
              className='mx-1'
              onClick={() => { if (listData.length !== 0) exportExcel() }}
              variant='info'
            >
              {/* Replace with your Icon component */}
              <span><Icon name="file-xls" /></span>
            </Button>
          </OverlayTrigger>
        )}

        {(shouldShowButton && !entryList) && (<OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-csv">Export to CSV</Tooltip>}
        >
          <Button
            className='mx-1'
            onClick={() => { if (listData.length !== 0) exportCSV() }}
            variant='success'
          >
            {/* Replace with your Icon component */}
            <span><Icon name="file-text" /></span>
          </Button>
        </OverlayTrigger>
        )}
        {(!shouldShowButton && entryList) && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-excel">Export to Excel</Tooltip>}
          >
            <Button
              className='mx-1'
              onClick={() => { if (listData.length !== 0) exportExcel() }}
              variant='info'
            >
              {/* Replace with your Icon component */}
              <span><Icon name="file-xls" /></span>
            </Button>
          </OverlayTrigger>
        )}

        {(!shouldShowButton && entryList) && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-csv">Export to CSV</Tooltip>}
          >
            <Button
              className='mx-1'
              onClick={() => { if (listData.length !== 0) exportCSV() }}
              variant='success'
            >
              {/* Replace with your Icon component */}
              <span><Icon name="file-text" /></span>
            </Button>
          </OverlayTrigger>
        )}


        {(shouldShowButton && !entryList && tab !== 'group') && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-csv">Export to TEXT</Tooltip>}
          >
            <Button
              className='mx-1'
              onClick={() => { if (listData.length !== 0) textFileFormat() }}
              variant='danger'
            >
              <span><Icon name="file" /></span>
            </Button>
          </OverlayTrigger>
        )}
      </>
    </div>
  );
};


// custom checkbox
const customCheckbox = React.forwardRef(({ row, onClick, ...rest }, ref) => {
  return (
    <div className="form-check" id={rest.name}>
      <input
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
    </div>
  );
});


function DataTableComponent({ type, directFilterValue, rowSelected, setParams, sort, headerValue, noNeedPagination, page_value, onPaginate, code, tableHead, headerData, pdf, print, printFunc, showType, list, data, columns, className, expandableRows, add, report, edit, actions, filter, tableClassName, selectableRows, showFilters, toggleFilters, conditionalRowStyles, navigate, reportPage, tab, ...props }) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [showItemPerPage, setShowItemPerPage] = useState(25);
  const [mobileView, setMobileView] = useState(false);
  const [farmerType, setFarmerType] = useState('2')
  const [isPrinting, setIsPrinting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null)
  const [selectedRow, setSelectedRow] = useState([]);


  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRow(selectedRows.selectedRows);
    if (typeof rowSelected === 'function') {
      if (selectedRows.allSelected === true) {
        rowSelected("all Selected")
      } else if (selectedRows.allSelected === false) {
        const idArray = selectedRows?.selectedRows?.map(row => (row));
        rowSelected(idArray)
      }
    }
  };




  useEffect(() => {
    if (page_value) {
      setCurrentPage(page_value.page)
      setShowItemPerPage(page_value.page_count)
      setTotalCount(page_value.total_count)

    } else {
      setTotalCount(data?.length)
    }

  }, [page_value, data, headerValue])

  useEffect(() => {
    const filteredData = searchText
      ? data?.filter(item => {
        // Convert item.code to a string before using includes
        const codeString = String(item?.code || item?.farmer_code || item?.farmercode);
        return codeString.includes(searchText);
      })
      : data;

    setTableData(filteredData);
  }, [searchText, data]);


  // useEffect(() => {
  //   if (!searchText) {
  //     // If the search text is empty, no need to filter; show all data
  //     setTableData(data);
  //     return;
  //   }

  //   const sanitizedSearchText = searchText.toLowerCase();
  //   const filteredData = data?.filter(item => {
  //     // Check if any property of the data item contains the search text (case-insensitive)

  //  
  //     const findData = Object.values(item).some(value =>
  //       typeof value === 'string' && value.toLowerCase().includes(sanitizedSearchText)
  //     );

  //   });

  //   setTableData(filteredData);
  // }, [data, searchText]);



  // useEffect(() => {
  //   const filteredData = data?.flatMap(item => {
  //     // If search text is empty, show all data
  //     if (searchText.trim() === '') {
  //       return item;
  //     }

  //     // Otherwise, filter based on the search text
  //     return Object.values(item).some(value => {
  //       if (typeof value === 'string') {
  //         // For string values, perform a case-insensitive exact match
  //         return value.toLowerCase().includes(searchText.toLowerCase());
  //       } else if (typeof value === 'number' && !isNaN(value)) {
  //         // For numeric values, compare as numbers
  //         return value === parseInt(searchText);
  //       }
  //       return false; // For other types, skip the comparison
  //     }) ? item : null; // Return null for items that don't match the search text
  //   }).filter(item => item !== null);

  //   setTableData(filteredData);
  // }, [searchText, data]);


  const handleFarmer = (value) => {

  }

  // function to change the table design view to expanable under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const customStyles = {

    rows: {
      style: {
        // Adjust height
        minHeight: '35px', // override the row height
        maxHeight: '38px',

      }
    },

    cells: {
      style: {
        // borderLeft: '1px solid #ddd !important', // Add left border to data cells
        // borderRight: '1px solid #ddd !important', // Add right border to data cells
        paddingRight: '10px !important',
        paddingLeft: '15px !important',
      },
    },

  };

  const dataTableRef = React.createRef();

  const PrintFn = () => {
    setIsPrinting(true);
    setTimeout(() => {
      handlePrint()
    }, 100);
  }

  const handlePrint = useReactToPrint({
    content: () => dataTableRef.current,
    documentTitle: "Entry List",
    onBeforePrint: () => {

      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);

    },
  });

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ProfileDetails())
  }, [dispatch])

  const role = useSelector((state) => state.profile)
  const desiredColumns = ["Billno", "Fat", "Snf", "Kg", "Litre", "Avg.Rate", "Amount"];

  let headColumns = ''
  if (role?.data?.user_role === "admin") {
    // Assuming your columns array looks like this
    headColumns = [
      { name: "Billno", minWidth: 100 },
      { name: "Fat", minWidth: 150 },
      { name: "Snf", minWidth: 120 },
      { name: "Kg", minWidth: 130 },
      { name: "Litre", minWidth: 130 },
      { name: "Avg.Rate", minWidth: 140 },
      { name: "Amount", minWidth: 140 },
    ];
  } else {
    headColumns = [
      { name: "Billno", minWidth: 100 },
      { name: "Fat", minWidth: 150 },
      { name: "Snf", minWidth: 120 },
      { name: "Kg", minWidth: 130 },
      { name: "Avg.Rate", minWidth: 140 },
      { name: "Amount", minWidth: 140 },
    ]
  }


  const filteredColumns = headColumns.filter((column) => desiredColumns.includes(column.name));

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const handlePageChange = (newPage) => {
    // Update the current page state
    setCurrentPage(newPage);
    // Add any other logic you need here
    if (newPage)
      onPaginate(newPage, showItemPerPage, searchText)

  };

  const handleSort = (column, direction) => {
    if (typeof sort === 'function') {
      sort(column, direction);
      // You can perform any additional actions here based on the sorting
    }
  };


  // Text Format

  return (
    <div className="data-table-wrapper">
      <div className="data-table-top">
        <div className="data-table-search">
          <div className="d-flex align-items-center">
            {add && <Button className="mx-1" variant="success" onClick={() => navigate()}><Icon name="plus" /></Button>}
            {pdf && <Button className="mx-1" onClick={PrintFn} variant="secondary">
              <Icon name="file-pdf"></Icon>
            </Button>}
            {actions && <Export listData={data} entryList={list} filterValue={directFilterValue} type={type} codeType={code} tab={tab} />}

            {edit && <Button className="mx-1" variant="warning"><Icon name="edit" /></Button>}
            {print &&
              <Button className="mx-1" variant="warning" onClick={() => { if (data.length !== 0) printFunc() }}><Icon name="printer" /></Button>}
            {report && <Button className="mx-1" variant="info" onClick={() => reportPage()}><Icon name="activity"></Icon></Button>}
            {filter && <Button className="mx-1" variant={showFilters ? 'secondary' : 'primary'} onClick={toggleFilters}><Icon name={showFilters ? 'cross' : 'filter'} /></Button>}
          </div>

          {showType && (
            <div className="mx-1">
              <Form.Select size="md" onChange={(e) => handleFarmer(e.target.value)} value={farmerType}>
                <option value="1">VLCC Farmer</option>
                <option value="2">Direct Farmer</option>
                <option value="3">Bulk vendor</option>
              </Form.Select>
            </div>
          )}
        </div>
        <div className="data-table-action-wrap">
          {!noNeedPagination && <div className="data-table-select mx-1">
            <select className='form-select' onChange={(e) => {
              onPaginate(1, e.target.value, "")
              setShowItemPerPage(e.target.value)
            }} value={showItemPerPage}>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
              <option value='150'>150</option>
              <option value='200'>200</option>
            </select>
            <span className='text'>Per page</span>
          </div>}
          <div className="data-table-select">
            <input
              className="form-control"
              placeholder="Search"
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <Button className="mx-1" variant="secondary" onClick={() => onPaginate("", "", searchText)}><Icon name="search" /></Button>
        </div>
      </div>

      {
        (tableHead && data.length !== 0) && <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                {filteredColumns.map((column, index) => (
                  <th key={index} className="table-header-cell" style={{ width: "auto", textAlign: 'center' }}>
                    {column.name === 'Fat' ? `Avg.Fat: ${headerValue?.avg_fat}%` :
                      column.name === 'Billno' ? `No.Bills: ${headerValue?.count}` :
                        column.name === 'Snf' ? `Avg.Snf: ${headerValue?.avg_snf}%` :
                          column.name === 'Kg' ? `Tot.Kg: ${headerValue?.tot_qty_kg}` :
                            column.name === 'Litre' ? `Tot.Ltr: ${headerValue?.tot_qty_litre}` :
                              column.name === 'Avg.Rate' ? `Avg.Rate: ${headerValue?.tot_rate}` :
                                (column.name === 'Amount' ? `Amount : ${headerValue?.tot_amount}` : column.name)}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
      }

      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        highlightOnHover={true}
        progressPending={tableData ? false : true}
        className={tableClassName}
        persistTableHead
        onSort={handleSort}
        sortIcon={<div className="data-table-sorter"></div>}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        expandableRows={expandableRows}
        expandableRowsComponent={({ data }) => (
          // Pass the expanded row data to your custom component
          <ExpandedComponent data={data} />
        )}
        // expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        onSelectedRowsChange={handleSelectedRowsChange}
        // selectableRowSelected={(row) => row.ready_to_post === "1"}
        paginationComponent={({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
          <div className="data-table-bottom">
            {!noNeedPagination && <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={showItemPerPage}
              totalItems={totalCount}
              paginate={handlePageChange}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
              className="pagination-element"
            />}
          </div>
        )}
      />

      <div >
        {isPrinting && <CustomDataTable ref={dataTableRef} tableData={data} headerData={headerData} setIsPrinting={setIsPrinting} />}
      </div>
    </div >
  )
}

export default DataTableComponent;



